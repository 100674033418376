import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: '#F2F5F9',
    _dark: {
      backgroundColor: 'gray.700'
    }
  }
})

export const cardTheme = defineMultiStyleConfig({
  variants: {
    confirm: baseStyle
  }
})
