import React, { useContext, useEffect } from 'react'
import themes from '../theme/theme'

export interface FooterLink {
  name: string
  url: string
}

export interface ITenantContext {
  tenant: {
    id: string
    name: string
    website: string
    logoUrl: string
    faviconUrl: string
    defaultPhoneNumber: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme: Record<string, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    themeEmbed: Record<string, any>
    footerLinks: FooterLink[]
  }
}

export const TenantContext = React.createContext<ITenantContext>({
  tenant: {
    id: '',
    name: '',
    website: '',
    logoUrl: '',
    faviconUrl: '',
    defaultPhoneNumber: '',
    theme: {},
    themeEmbed: {},
    footerLinks: []
  }
})

export const TenantProvider = ({ children }: { children: React.ReactNode }) => {
  const value = getTenant(window.location.hostname)
  console.debug('tenant loaded', value.tenant)
  useEffect(() => {
    document.title = value.tenant.name
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | undefined
    if (link) {
      link.href = value.tenant.faviconUrl
    } else {
      const link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
      link.href = value.tenant.faviconUrl
    }
  }, [value.tenant.faviconUrl, value.tenant.name])
  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
}

export const useTenant = () => {
  const context: ITenantContext = useContext(TenantContext)
  if (!context) {
    throw new Error('needs SessionContext as provider')
  }
  return context
}

const getTenant = (domain: string): ITenantContext => {
  console.debug('loading tenant', domain)
  switch (domain) {
    case 'acme.fsplatform.net':
    case 'acme.fsplatformdev.net':
    case 'acme.fsplatformtest.net':
      return {
        tenant: {
          id: 'acme',
          name: 'ACME Services',
          theme: themes.acme,
          themeEmbed: themes.acmeEmbed,
          defaultPhoneNumber: '+611300123456',
          website: 'https://www.google.com',
          logoUrl: 'https://static.fsplatform.net/tenant/acme/images/logo.webp',
          faviconUrl: 'https://static.fsplatform.net/tenant/acme/images/favicon.svg',
          footerLinks: []
        }
      }
    case 'metacortex.fsplatform.net':
    case 'metacortex.fsplatformdev.net':
    case 'metacortex.fsplatformtest.net':
      return {
        tenant: {
          id: 'metacortex',
          name: 'Metacortex',
          theme: themes.metacortex,
          themeEmbed: themes.metacortexEmbed,
          defaultPhoneNumber: '+611300123456',
          website: 'https://www.google.com',
          logoUrl: 'https://static.fsplatform.net/tenant/metacortex/images/logo.webp',
          faviconUrl: 'https://static.fsplatform.net/tenant/metacortex/images/favicon.svg',
          footerLinks: []
        }
      }

    case 'fallonsolutions-sandbox.fsplatformdev.net':
    case 'fallonsolutions-sandbox.fsplatformtest.net':
    case 'fallonsolutions-sandbox.fsplatform.net':
      return {
        tenant: {
          id: 'fallonsolutions-sandbox',
          name: 'Fallon Solutions SANDBOX',
          theme: themes.fallonSolutions,
          themeEmbed: themes.fallonSolutionsEmbed,
          defaultPhoneNumber: '+611300054488',
          website: 'https://www.fallonsolutions.com.au',
          logoUrl: 'https://static.fsplatform.net/tenant/fallonsolutions-sandbox/images/logo.webp',
          faviconUrl: 'https://static.fsplatform.net/tenant/fallonsolutions-sandbox/images/favicon.svg',
          footerLinks: [
            {
              name: 'Privacy policy',
              url: 'https://fallonsolutions.com.au/privacy'
            },
            {
              name: 'Terms and conditions',
              url: 'https://fallonsolutions.com.au/terms-conditions'
            }
          ]
        }
      }

    case 'app.fallonsolutions.com.au':
    case 'fallonsolutions.fsplatform.net':
    case 'fallonsolutions.fsplatformdev.net':
    case 'fallonsolutions.fsplatformtest.net':
    case 'app.fallondemo.net':
    default:
      return {
        tenant: {
          id: 'fallonsolutions',
          name: 'Fallon Solutions',
          theme: themes.fallonSolutions,
          themeEmbed: themes.fallonSolutionsEmbed,
          defaultPhoneNumber: '+611300054488',
          website: 'https://www.fallonsolutions.com.au',
          logoUrl: 'https://static.fsplatform.net/tenant/fallonsolutions/images/logo.webp',
          faviconUrl: 'https://static.fsplatform.net/tenant/fallonsolutions/images/favicon.svg',
          footerLinks: [
            {
              name: 'Privacy policy',
              url: 'https://fallonsolutions.com.au/privacy'
            },
            {
              name: 'Terms and conditions',
              url: 'https://fallonsolutions.com.au/terms-conditions'
            }
          ]
        }
      }
  }
}
