import { ColorMode } from '@chakra-ui/react'

export class SessionColorModeManager {
  colorMode: ColorMode | undefined

  storageType: 'localStorage' | 'cookie' = 'localStorage'

  get type() {
    return this.storageType
  }

  ssr = false

  get(): ColorMode | undefined {
    return this.colorMode
  }

  set(value: ColorMode | 'system') {
    if (value === 'system') {
      this.colorMode = SessionColorModeManager.currentColorMode()
    } else {
      this.colorMode = value
    }
  }

  static currentColorMode(): ColorMode {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }
}
