import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle({
  icon: {
    color: 'gray.500',
    _dark: {
      color: 'gray.400'
    }
  },
  container: {
    background: 'gray.100',
    borderRadius: 6,
    _dark: {
      background: 'gray.700'
    }
  }
})

export const alertTheme = defineMultiStyleConfig({
  variants: {
    callout: baseStyle
  }
})
