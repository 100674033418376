import { ReactNode, useEffect } from 'react'

const useMaze = (enableMaze: boolean) => {
  useEffect(() => {
    console.log('enable maze', enableMaze)
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = ` (function (m, a, z, e) {
          var s, t;
          try {
            t = m.sessionStorage.getItem('maze-us');
          } catch (err) {}
         
          if (!t) {
            t = new Date().getTime();
            try {
              m.sessionStorage.setItem('maze-us', t);
            } catch (err) {}
          }
         
          s = a.createElement('script');
          s.src = z + '?apiKey=' + e;
          s.async = true;
          a.getElementsByTagName('head')[0].appendChild(s);
          m.mazeUniversalSnippetApiKey = e;
        })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '0f475c14-df39-45a8-8a5d-70f008042d40');`

    if (enableMaze) {
      document.head.appendChild(script)
    }

    return () => {
      console.log('remove maze')
      // document.head.removeChild(script)
    }
  }, [])
}

export const MazeProvider = (props: { children: ReactNode }) => {
  const enableMaze = window.location.hostname === 'fallonsolutions.fsplatformtest.net'

  useMaze(enableMaze)
  return <>{props.children}</>
}
