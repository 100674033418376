import { ThemeConfig, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { alertTheme } from '../theme/components/alert'
import { cardTheme } from '../theme/components/card.tsx'
import { checkboxTheme } from './components/checkbox.tsx'
import { customIcons } from './icons'

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true
}

const icons = {
  ...customIcons
}

const components = {
  Checkbox: checkboxTheme,
  Card: cardTheme,
  Alert: alertTheme
}

const fonts = {
  heading: `"Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";sans-serif`
}

const themes = {
  fallonSolutions: extendTheme(withDefaultColorScheme({ colorScheme: 'red' }), {
    icons,
    config,
    components,
    fonts
  }),
  fallonSolutionsEmbed: extendTheme(withDefaultColorScheme({ colorScheme: 'red' }), {
    // icons,
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false
    },
    components,
    fonts,
    styles: {
      global: () => ({
        body: {
          bg: ''
        }
      })
    }
  }),
  fallonSolutionsSandbox: extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
    icons,
    config,
    components,
    fonts
  }),
  fallonSolutionsSandboxEmbed: extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
    // icons,
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false
    },
    components,
    fonts,
    styles: {
      global: () => ({
        body: {
          bg: ''
        }
      })
    }
  }),
  acme: extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
    icons,
    config,
    components
  }),
  acmeEmbed: extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
    // icons,
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false
    },
    components,
    styles: {
      global: () => ({
        body: {
          bg: ''
        }
      })
    }
  }),
  metacortex: extendTheme(withDefaultColorScheme({ colorScheme: 'green' }), {
    icons,
    config,
    components
  }),
  metacortexEmbed: extendTheme(withDefaultColorScheme({ colorScheme: 'green' }), {
    // icons,
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false
    },
    components,
    styles: {
      global: () => ({
        body: {
          bg: ''
        }
      })
    }
  })
}

export default themes
