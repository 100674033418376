export const checkboxTheme = {
  parts: ['control'],
  variants: {
    alignTop: {
      control: {
        alignSelf: 'start',
        mt: '1'
      }
    }
  }
}
