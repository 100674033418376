// essential imports for showing initial loading spinner only
import { Container, Spinner } from '@chakra-ui/react'
import { StrictMode, Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import * as zoid from 'zoid/dist/zoid.frame'
import { Chakra } from './chakra'
import { TenantProvider } from './tenant'
import { MazeProvider } from './maze'

// non-essential to showing initial loading spinner use lazy import
const Analytics = lazy(() => import('./analytics'))
const App = lazy(() => import('./app'))
const AppMeasured = lazy(() => import('./app-measured'))
const CampaignProvider = lazy(() => import('./campaign'))
const ConfigProvider = lazy(() => import('./config'))
const EventCallbackProvider = lazy(() => import('./event-callback-provider'))
const GraphQLProvider = lazy(() => import('./graphql'))
const OfficePhoneProvider = lazy(() => import('./office-phone'))
const RecaptchaProvider = lazy(() => import('./recaptcha'))
const SentryProvider = lazy(() => import('./sentry'))
const SessionProvider = lazy(() => import('./session'))

export const BookingComponent = zoid.create({
  tag: 'fs-booking',
  url: `${new URL(import.meta.url).origin}/embed/book`
})

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <TenantProvider>
      <MazeProvider>
        <Chakra>
          <Suspense fallback={<Container p={4}>{<Spinner />}</Container>}>
            <SessionProvider>
              <GraphQLProvider>
                <ConfigProvider>
                  <SentryProvider>
                    <Analytics>
                      <RecaptchaProvider>
                        <CampaignProvider>
                          <OfficePhoneProvider>
                            <EventCallbackProvider {...window.xprops}>
                              {window.xprops ? <AppMeasured /> : <App />}
                            </EventCallbackProvider>
                          </OfficePhoneProvider>
                        </CampaignProvider>
                      </RecaptchaProvider>
                    </Analytics>
                  </SentryProvider>
                </ConfigProvider>
              </GraphQLProvider>
            </SessionProvider>
          </Suspense>
        </Chakra>
      </MazeProvider>
    </TenantProvider>
  </StrictMode>
)
