import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { useMemo } from 'react'
import { SessionColorModeManager } from '../common/session-color-mode-manager'
import './app.css'
import { useTenant } from './tenant'

interface ChakraProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const Chakra = ({ children }: ChakraProps) => {
  const { tenant } = useTenant()
  const isEmbedPath: boolean = useMemo(
    () => window.location.pathname.startsWith('/embed/') || window.location.pathname.startsWith('/modal/'),
    []
  )
  const theme = isEmbedPath ? tenant.themeEmbed : tenant.theme

  const colorModeManager = new SessionColorModeManager()

  return (
    <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      {children}
    </ChakraProvider>
  )
}
